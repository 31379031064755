import React, { useEffect, useState } from 'react';
import { child, onValue, remove } from 'firebase/database';
import { Navigate } from 'react-router-dom';
import { SIGN_IN } from './routes';
import { Puff } from 'react-loader-spinner';
import { onAuthStateChanged } from 'firebase/auth';
import './tables.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellConcierge } from '@fortawesome/free-solid-svg-icons';

function TableComponent(props) {
    const { table, tableNumber, closeTable, clearCallingWaiter } = props;
    let sumTotal = 0;

    const removeAnimation = event => {
        event.target.classList.remove("animateFlicker");
    }

    return (
        <div className='table' key={tableNumber}>
            <div className='tableHeader'>
                {tableNumber}
                {table.callingWaiter &&
                    <FontAwesomeIcon className='callingWaiterIcon animateFlicker' icon={faBellConcierge}
                        onClick={() => clearCallingWaiter(tableNumber)} />}
            </div>
            <div className='tableBody'>
                {Object.values(table).map((order, index) => (
                    order.orderLines &&
                    <div className='order animateFlicker' onClick={event => removeAnimation(event)} key={index}>
                        <p>{index + 1}</p>
                        <ul>
                            {Object.values(order.orderLines).map((line, index) => {
                                sumTotal += line.total;
                                return (
                                    <li className='orderItem' key={index}>
                                        {line.cantidad} x {line.nombre}<span>${line.total}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                ))}
            </div>
            <div className='tableFooter'>
                <button onClick={() => closeTable(tableNumber)}>Cerrar Mesa</button>
                <p>${sumTotal}</p>
            </div>
        </div>
    );
}

function TablesList(props) {
    const [user, setUser] = useState();
    const [tables, setTables] = useState();

    useEffect(() => {
        let unsubscribeFunction = onAuthStateChanged(props.firebase.auth, user => {
            setUser(user);
        });
        return () => {
            unsubscribeFunction();
        };
    }, [props.firebase.auth, user]);

    useEffect(() => {
        if (user) {
            let unsubscribeFunction = onValue(props.firebase.tables(user.uid), dataSnapshot => {
                let tables = {};
                dataSnapshot.forEach(table => {
                    tables = { ...tables, [table.key]: table.val() };
                });
                setTables(tables);
            })
            return () => {
                unsubscribeFunction();
            };
        }
    }, [props.firebase, user]);

    if (user === null) {
        return <Navigate to={SIGN_IN} replace={true} />
    }

    const closeTable = tableNumber => {
        remove(props.firebase.table(user.uid, tableNumber));
    }

    const clearCallingWaiter = tableNumber => {
        let tableRef = props.firebase.table(user.uid, tableNumber);
        let callingWaiterRef = child(tableRef, 'callingWaiter');
        remove(callingWaiterRef);
    }

    if (tables) {
        let activeTables = Object.entries(tables);
        return (
            <div className='tables'>
                {activeTables.map(([tableNumber, table]) => (
                    <TableComponent
                        key={tableNumber}
                        tableNumber={tableNumber}
                        table={table}
                        closeTable={closeTable}
                        clearCallingWaiter={clearCallingWaiter}
                    />
                ))}
            </div>
        );
    } else {
        return (
            <div className='fullPage'>
                <div className='centered'>
                    <Puff color='#178390' />
                </div>
            </div>
        )
    }
}

export default TablesList