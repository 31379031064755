import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ErrorPage from './errorpage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AppPage from './App';
import reportWebVitals from './reportWebVitals';
import * as ROUTES from './routes';
import SignUp from './signup';
import SignIn from './signin';
import HomePage from './home';
import ProductList from './products';
import Tables from './tables';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ROUTES.SIGN_UP,
        element: <SignUp />
      },
      {
        path: ROUTES.SIGN_IN,
        element: <SignIn />
      },
      {
        path: ROUTES.HOME,
        element: <HomePage />
      },
      {
        path: ROUTES.MENU,
        element: <ProductList />
      },
      {
        path: ROUTES.TABLES,
        element: <Tables />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
