import React, { useState } from "react";
import { withFirebase } from "./context";
import { Navigate } from "react-router-dom";
import { HOME } from './routes';
import "./signup.css";

export function SignupForm(props) {
  const [authUser, setAuthUser] = useState(props.firebase.auth.currentUser);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (!email || !password || !confirmPassword) {
      setErrorMessage('All fields are required.');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    props.firebase.doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        //console.log("signed up: ", authUser);
        setAuthUser(authUser);
      })
      .catch(error => {
        console.log("Error signing up: ", error);
      });
  };

  const handleSignupWithGoogle = async (event) => {
    event.preventDefault();

    props.firebase.doSignInWithPopup()
      .then(authUser => {
        //console.log("signed up: ", authUser);
        setAuthUser(authUser);
      })
      .catch(error => {
        console.log("Error signing up: ", error);
      });
  }

  return (
    authUser ? <Navigate to={HOME} replace={true} /> :
      <div className="form-container">
        <h2>Signup</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              id="password"
              name="password"
              required
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirm-password">Repetir Contraseña</label>
            <input
              type="password"
              id="confirm-password"
              name="confirm-password"
              required
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
          </div>
          <div className="form-group">
            <button type="submit" onClick={handleSignUp}>Registrarse</button>
          </div>
        </form>
        <hr />
        <div className="form-group">
          <button type="button" onClick={handleSignupWithGoogle}>Registrarse con Google</button>
        </div>
      </div>
  );
}

const SignUp = withFirebase(SignupForm);

export default SignUp;