import LogRocket from 'logrocket';
import './App.css';
import FirebaseContext from './context';
import Firebase from './firebase';
import { Outlet } from 'react-router';


LogRocket.init('qwy5vb/dmenu');

function AppPage() {
  return (
    <>
      <div id='detail'>
        <FirebaseContext.Provider value={new Firebase()}>
          <Outlet />
        </FirebaseContext.Provider>
      </div>
    </>
  )
}

export default AppPage;
