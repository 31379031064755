import React, { useEffect, useState, useRef } from 'react';
import { Navigate } from 'react-router';
import { withFirebase } from './context';
import { onValue } from 'firebase/database';
import RestaurantForm from './restaurant';
import { SIGN_IN } from './routes';
import './signup.css';
import { Puff } from 'react-loader-spinner';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import TablesList from './tables';
import { FileUpload } from 'primereact/fileupload'
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card'
import { Button } from 'primereact/button';


//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import QRCode from 'react-qr-code';

const apiUrl = process.env.REACT_APP_STATIC_MENU_GATEWAY
const dqrUrl = process.env.REACT_APP_DQR_GATEWAY

function Dashboard2(props) {
    const [state, setState] = useState("loading");
    const [user, setUser] = useState();
    const [files, setFiles] = useState();

    const [token, setToken] = useState();
    const [qrCodeUrl, setQrCodeUrl] = useState("");
    const qrCodeRef = useRef(null);
    const prefix = `
        <svg xmlns="http://www.w3.org/2000/svg" height="288" viewBox="0 0 288 288" width="288">
            <rect width="100%" height="100%" fill="white"/>
            `
    const suffix = '</svg>'

    const downloadSVG = () => {
        if (qrCodeRef.current) {
            const infix = qrCodeRef.current.outerHTML.replace("svg", 'svg x="16" y="16"');
            const svgText = prefix + infix + suffix;
            console.log(svgText);
            const svgBlob = new Blob([svgText], { type: 'image/svg+xml' });
            console.log(svgBlob);
            const svgUrl = URL.createObjectURL(svgBlob);
            const a = document.createElement('a');
            a.href = svgUrl;
            a.download = 'qr_code.svg';
            a.click();

            URL.revokeObjectURL(svgUrl);
        }
    };

    useEffect(() => {
        let unsubscribeFunction = onAuthStateChanged(props.firebase.auth, user => {
            setUser(user);
            if (user === null) {
                setState("loggedOff");
            } else {
                getIdToken(user, true).then(token => setToken(token));
            }
        });
        return unsubscribeFunction;
    }, [user, props.firebase])

    useEffect(() => {
        if (user) {
            let unsubscribeFunction = onValue(props.firebase.user(user.uid), dataSnapshot => {
                if (!dataSnapshot.hasChild('restaurant')) {
                    setState('restaurant');
                    return;
                }
                if (!dataSnapshot.hasChild('menu')) {
                    setState('products');
                    return;
                }
                setState('finished');
            });
            return unsubscribeFunction;
        } else {
            console.log("no user, skip onValue subscription.");
        }
    }, [user, props.firebase])

    const beforeSendCallback = (event) => {
        event.xhr.setRequestHeader('Authorization', `Bearer ${token}`)
    }

    const afterUploadCallback = (event) => {
        //take url and add the google preview url, post it to dqr, take that response and build a QR from it using react QR code library
        let url = JSON.parse(event.xhr.response).url
        if (url.endsWith(".pdf")) {
            url = `https://docs.google.com/viewerng/viewer?url=${url}`
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            url,
            customer: user.uid,
            group: "signup"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        fetch(`${dqrUrl}`, requestOptions)
            .then(response => {
                if (response.ok)
                    return response.json();
            })
            .then(data => {
                setQrCodeUrl(`${dqrUrl}/${data.token}`);
                setState("uploaded");
            })
    }

    switch (state) {
        case 'loggedOff':
            return <Navigate to={SIGN_IN} replace={true} />
        case 'loading':
            return (
                <div className='fullPage'>
                    <div className='centered'>
                        <Puff color='red' />
                    </div>
                </div>
            )
        case 'restaurant':
            return <RestaurantForm {...props} />
        case 'products':
            return (
                <Panel header="Subí acá tu menú">
                    <FileUpload
                        style={{ maxWidth: '512px' }}
                        onBeforeSend={beforeSendCallback}
                        onUpload={afterUploadCallback}
                        name="files[]"
                        url={apiUrl}
                        accept="image/*,application/pdf"
                        maxFileSize={20000000}
                        emptyTemplate={
                            <div>
                                <p className="m-0">Podes usar el boton de agregar o arrastrar archivos aca para subirlos.</p>
                                <p> Se acepta una imagen o un pdf.</p>
                            </div>
                        }
                        chooseLabel='Agregar'
                        uploadLabel='Subir'
                        cancelLabel='Limpiar'
                    />
                </Panel>
            )
        case 'uploaded':
            return (
                <Panel header="Este es tú código QR!">
                    <div style={{ display: "flex", flexDirection: "column", maxWidth: "288px", minWidth: "288px" }}>
                        <div style={{ background: 'white', padding: '16px' }}>
                            <QRCode ref={qrCodeRef}
                                level='H'
                                size={256}
                                value={qrCodeUrl}
                            />
                        </div>
                        <Button onClick={downloadSVG}>Descargar</Button>
                    </div>
                </Panel>
            )
        case 'finished':
            console.log("TablesList");
            return <TablesList {...props} />
        default:
            console.log("????");
    }
}

export function Home(props) {
    return <Dashboard2 {...props} />
}

const HomePage = withFirebase(Home);

export default HomePage;