import React, { useEffect, useState, useRef } from 'react';
import { Puff } from 'react-loader-spinner';
import { onValue, update } from 'firebase/database'
import { withFirebase } from './context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellConcierge } from '@fortawesome/free-solid-svg-icons';
import { Toast } from 'primereact/toast';
import Survey from './Survey';
import CookieConsent from "react-cookie-consent";
import { getCookie, setCookie } from './cookies';
import { v4 as uuidv4 } from 'uuid';
import Div100vh from 'react-div-100vh'
import CurrencyInput from 'react-currency-input-field';

const apiUrl = process.env.REACT_APP_API_GATEWAY

const SPLIT_STRATEGY_MANUAL = "manual"
const SPLIT_STRATEGY_MESA = "mesa"
const SPLIT_STRATEGY_MIO = "mio"
const SPLIT_STRATEGY_PROPORCIONAL = "proporcional"

function ProductList(props) {
    const urlParams = new URLSearchParams(window.location.search);
    const restaurantId = urlParams.get("mid");
    require(`./css/${restaurantId}.css`);
    const toast = useRef(null);
    const tableNumber = urlParams.get("table");
    const [restaurant, setRestaurant] = useState({});
    const [dbSections, setDbSections] = useState(null);
    const [items, setItems] = useState({});
    const [table, setTable] = useState({});
    const [paymentCreated, setPaymentCreated] = useState(false);
    const [posUrl, setPosUrl] = useState();
    const [customerId, setCustomerId] = useState(uuidv4());
    const [splitStrategy, setSplitStrategy] = useState(SPLIT_STRATEGY_MIO);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        // Read the customer identifier from the cookie
        const storedCustomerId = getCookie('customerId');
        if (storedCustomerId) {
            setCustomerId(storedCustomerId);
        } else {
            // Set the customer identifier in the cookie
            setCookie('customerId', customerId, 30);
        }
    }, [customerId]);

    useEffect(() => {
        var myHeaders = new Headers();
        var languages = navigator.languages;
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            customerId,
            restaurantId,
            "table": tableNumber,
            languages
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${apiUrl}/tab`, requestOptions)
            .then(response => {
                if (response.ok)
                    return response.json();
            })
            .then(data => {
                let sections = {};
                Object.keys(data.menu).forEach(key => {
                    sections[key] = data.menu[key];
                });
                setDbSections(sections);
                setRestaurant(data.restaurant);
                setLoading(false);
                if (!tableNumber) {
                    setPaymentCreated(true);
                }
            });
    }, [customerId, restaurantId, tableNumber]);

    useEffect(() => {
        let unsubscribeFunction = onValue(props.firebase.table(restaurantId, tableNumber), dataSnapshot => {
            let orders = {};
            dataSnapshot.forEach(orderSnapshot => {
                if (orderSnapshot.val().orderLines) {
                    orders[orderSnapshot.key] = orderSnapshot.val();
                }
                if (orderSnapshot.key === 'locked') {
                    setPaymentCreated(true);
                }
            });
            setTable(orders);
        });
        return unsubscribeFunction;
    }, [restaurantId, props.firebase, tableNumber]);

    const addItem = (sectionId, productId) => {
        let section = dbSections[sectionId]
        let producto = section["productos"][productId];
        let cantidad = items[productId]?.cantidad;
        cantidad = cantidad ? cantidad + 1 : 1;
        let item = {
            sectionId,
            nombre: producto.nombre,
            precio: producto.precio,
            cantidad: cantidad,
            total: producto.precio * cantidad
        }

        setItems({ ...items, [productId]: item });
    }

    const removeItem = (sectionId, productId) => {
        let section = dbSections[sectionId]
        let producto = section["productos"][productId];
        let orderLines = items;
        let cantidad = orderLines[productId].cantidad - 1;
        let orderLine = {
            sectionId,
            nombre: producto.nombre,
            precio: producto.precio,
            cantidad: cantidad,
            total: producto.precio * cantidad
        }
        if (orderLine.cantidad === 0) {
            const { [productId]: remove, ...rest } = orderLines;
            setItems({ ...rest });
        } else {
            setItems({ ...orderLines, [productId]: orderLine });
        }
    }

    const confirmOrder = () => {
        let orderLines = items;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            customerId,
            restaurantId,
            "table": tableNumber,
            orderLines
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${apiUrl}/order`, requestOptions)
            .then(response => {
                if (response.ok)
                    return response.json();
            }).then(data => {
                setItems({})
            }).catch(error => {
                console.error('error', error);
            });
    }

    const callWaiter = () => {
        let tableRef = props.firebase.table(restaurantId, tableNumber);
        update(tableRef, { callingWaiter: true }).then(_ => {
            toast.current.show({ severity: 'info', summary: 'Listo!', detail: 'Ya le avisamos al mozo.' });
        });
    }

    const payOrders = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let manualAmount = 0;
        if (splitStrategy === SPLIT_STRATEGY_MANUAL) {
            let sumTotalElement = document.getElementById("sumTotal")
            manualAmount = sumTotalElement.value.substring(1)
            if (!manualAmount) {
                toast.current.show({ severity: 'error', summary: 'Ingrese monto!', detail: 'Debe ingresar el monto a pagar.' });
                sumTotalElement.focus();
                return;
            }
        }

        var raw = JSON.stringify({
            customerId,
            restaurantId,
            "table": tableNumber,
            manualAmount,
            splitStrategy
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${apiUrl}/merchant_order`, requestOptions)
            .then(response => {
                setPaymentCreated(true);
                if (response.ok)
                    return response.json();
            }).then(response => {
                setLoading(false);
                setPosUrl(response.posUrl);
            }).catch(error => {
                console.error('error', error);
                setLoading(false);
            });
    }

    const onChange = (event) => {
        event.preventDefault();
        setSplitStrategy(event.target.value);
    }

    const navigateToSection = event => {
        event.preventDefault();
        let element = document.getElementById(event.target.value);
        element.scrollIntoView({ behavior: 'smooth' });
    }

    if (loading === true) { //avoid truthy values
        return (
            <div className="fullPage">
                <div className="centered">
                    <Puff color='red' />
                </div>
            </div>
        )
    }

    if (posUrl) {
        return (
            <Div100vh className={`s${restaurantId}-fullPage`}>
                <div className={`s${restaurantId}-centered`}>
                    <h1>¡Perfecto!</h1>
                    <p>Ya podes pagar usando mercado pago con el siguiente boton:</p>
                    <div>
                        <a href={`${posUrl}`} >
                            <button className={`s${restaurantId}-btnPagar`}>PAGAR</button>
                        </a>
                    </div>
                    <p>¡Volvé pronto!</p>
                </div>
                <Survey firebase={props.firebase} restaurantId={restaurantId} tableNumber={tableNumber} savedScore={table.score} savedVolverias={table.volverias} />
            </Div100vh>
        )
    }

    if (dbSections) {
        const orderLines = Object.entries(paymentCreated ? {} : items);
        const orders = Object.values(table);

        const sections = Object.entries(dbSections);
        return (
            <Div100vh id={restaurantId} className={`s${restaurantId}-mainScreen`}>
                <TopBar restaurantId={restaurantId} sections={sections} navigate={navigateToSection} callWaiter={callWaiter} />
                <div className={`s${restaurantId}-menu`}>
                    <Toast ref={toast} position="top-left" />
                    <div className={`s${restaurantId}-menuHeader`}>
                        <p className={`s${restaurantId}-nombreRestaurante`}>{restaurant.name}</p>
                        {restaurant.slogan && <p className={`s${restaurantId}-slogan`}>{restaurant.slogan}</p>}
                    </div>
                    <div className={`s${restaurantId}-sectionsContainer`}>
                        {sections.map(([key, section]) => {
                            return <Section restaurantId={restaurantId} key={key} sectionId={key} section={section} items={items} addItem={addItem} removeItem={removeItem} paymentCreated={paymentCreated} />
                        })}
                    </div>
                </div >
                {(orderLines.length > 0 || orders.length > 0) &&
                    <div className={`s${restaurantId}-bottomBar`} >
                        <OrderLines restaurantId={restaurantId} orderLines={orderLines} confirmAction={confirmOrder} addItem={addItem} removeItem={removeItem} />
                        {orderLines.length === 0 && <MealTotal restaurantId={restaurantId} orders={orders} customerId={customerId} splitStrategy={splitStrategy} payAction={payOrders} onChange={onChange} />}
                    </div>
                }
                <CookieConsent
                    //debug
                    overlay
                    buttonText="Aceptar"
                    style={{ maxWidth: '500px' }}
                >
                    Este sitio usa cookies para evaluar y mejorar tu experiencia.
                </CookieConsent>
            </Div100vh>
        );
    }
}

function TopBar(props) {
    const { restaurantId, sections, navigate, callWaiter } = props;
    return (
        <div className={`s${restaurantId}-topBar s${restaurantId}-background`}>
            <label>Secciones:</label>
            <select onChange={event => navigate(event)}>
                {sections.map(([key, section]) => {
                    return (
                        <option key={key} value={key}>{section.nombre}</option>
                    )
                })}
            </select>
            <FontAwesomeIcon size='xl' icon={faBellConcierge} onClick={() => callWaiter()} />
        </div>
    )
}

function Section(props) {
    const { restaurantId, sectionId, section, items, addItem, removeItem, paymentCreated } = props;
    const customStyles = section.customStyles ?? "";
    return (
        <div id={sectionId} className={`s${restaurantId}-section ${customStyles}`} >
            <div className={`s${restaurantId}-sectionHeader`}>
                <p className={`s${restaurantId}-sectionTitle`} id={`${sectionId}`}>{section.nombre}</p>
                {section.descripcion && <p className={`s${restaurantId}-sectionDescription`}>{section.descripcion}</p>}
                {section.nota && <p className={`s${restaurantId}-sectionNote`}>{section.nota}</p>}
            </div>
            <div className={`s${restaurantId}-sectionBody`}>
                {Object.entries(section.productos || {}).map(([key, producto]) => {
                    return (
                        <ProductLine
                            key={key}
                            restaurantId={restaurantId}
                            orderLine={items[key]}
                            producto={producto}
                            addAction={() => addItem(sectionId, key)}
                            removeAction={() => removeItem(sectionId, key)}
                            paymentCreated={paymentCreated}
                        />)
                })}
            </div>
        </div>
    );
}

function ProductLine(props) {
    const { restaurantId, orderLine, producto, addAction, removeAction, paymentCreated } = props;
    let cantidad = orderLine?.cantidad;
    let customStyles = producto.customStyles ?? "";
    return (
        <div className={`s${restaurantId}-product ${customStyles}`}>
            <p className={`s${restaurantId}-name`}>{producto.nombre}
                {producto.acotacion && <span className={`s${restaurantId}-sidenote`}>{producto.acotacion}</span>}
            </p>
            {producto.alcohol && <p className={`s${restaurantId}-alcohol`}>{producto.alcohol}</p>}
            {producto.ibu && <p className={`s${restaurantId}-ibu`}>{producto.ibu}</p>}
            {producto.srm && <p className={`s${restaurantId}-srm`}>{producto.srm}</p>}
            {producto.nota && <p className={`s${restaurantId}-note`}>{producto.nota}</p>}
            {producto.descripcion && <p className={`s${restaurantId}-description`}>{producto.descripcion}</p>}
            {producto.precio && <p className={`s${restaurantId}-price`}>${producto.precio}</p>}
            {(cantidad && producto.precio)
                ? <button className={`s${restaurantId}-removeButton`} onClick={removeAction}>-</button>
                : <button className={`s${restaurantId}-removeButton s${restaurantId}-hidden`}>-</button>
            }
            {cantidad
                ? <button disabled={true} className={`s${restaurantId}-quantity s${restaurantId}-show`}>{cantidad}</button>
                : <button disabled={true} className={`s${restaurantId}-quantity s${restaurantId}-hidden`}>0</button>
            }
            {(producto.precio && paymentCreated === false)
                ? <button className={`s${restaurantId}-addButton`} onClick={addAction}>+</button>
                : <button className={`s${restaurantId}-addButton s${restaurantId}-hidden`} onClick={addAction}>+</button>
            }
        </div >
    );
}

function OrderLines(props) {
    let { restaurantId, orderLines, confirmAction, addItem, removeItem } = props;
    let currentOrderTotal = 0;

    if (orderLines.length === 0) return null;

    return (
        <div className={`s${restaurantId}-orderLines`}>
            {orderLines.map(([productId, orderLine]) => {
                currentOrderTotal = currentOrderTotal + orderLine.total;
                return (
                    <div className={`s${restaurantId}-orderLine`} key={productId}>
                        <p className={`s${restaurantId}-olName`}>{orderLine.nombre}</p>
                        <p className={`s${restaurantId}-olTotal`}>${orderLine.precio * orderLine.cantidad}</p>
                        <button className={`s${restaurantId}-removeButton`} onClick={() => removeItem(orderLine.sectionId, productId)}>-</button>
                        <button disabled={true} className={`s${restaurantId}-quantity s${restaurantId}-show`}>{orderLine.cantidad}</button>
                        <button className={`s${restaurantId}-addButton`} onClick={() => addItem(orderLine.sectionId, productId)}>+</button>
                    </div>
                )
            })}
            {confirmAction &&
                <>
                    <hr className={`s${restaurantId}-horizontalDivider`}></hr>
                    <div className={`s${restaurantId}-totalLine`}>
                        <p className={`s${restaurantId}-lblTotal`}>Total</p>
                        <p className={`s${restaurantId}-orderTotal`}>${currentOrderTotal}</p>
                    </div>
                    <button className={`s${restaurantId}-btnConfirmar`} onClick={confirmAction}>CONFIRMAR</button>
                </>
            }
        </div>
    )
}

function MealTotal(props) {
    let { restaurantId, orders, customerId, splitStrategy, payAction, onChange } = props;
    const myOrders = orders.filter(val => val.customerId === customerId);
    const customers = orders.reduce((accumulator, currentValue) => {
        accumulator.add(currentValue.customerId);
        return accumulator;
    }, new Set());
    let sumTotal = 0
    switch (splitStrategy) {
        case SPLIT_STRATEGY_MIO:
            sumTotal = myOrders.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0)
            break;
        case SPLIT_STRATEGY_PROPORCIONAL:
            sumTotal = orders.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
            sumTotal /= customers.size
            sumTotal = sumTotal.toFixed(2);
            break;
        case SPLIT_STRATEGY_MESA:
        default:
            sumTotal = orders.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
            break;
    }

    if (sumTotal === 0) return null;

    return (
        <div className={`s${restaurantId}-orders`}>
            <div className={`s${restaurantId}-orders`}>
                <label>Pagar: </label>
                <select value={splitStrategy} onChange={event => onChange(event)}>
                    <option value={SPLIT_STRATEGY_MIO}>Lo mio</option>
                    <option value={SPLIT_STRATEGY_MESA}>La mesa</option>
                    <option value={SPLIT_STRATEGY_PROPORCIONAL}>Partes iguales</option>
                    <option value={SPLIT_STRATEGY_MANUAL}>Ingreso manual</option>
                </select>
                <label>Total: </label>
                {
                    splitStrategy === SPLIT_STRATEGY_MANUAL
                        ? <CurrencyInput
                            id="sumTotal"
                            fixedDecimalLength={0}
                            disableGroupSeparators
                            prefix='$'
                        />
                        : <p className={`s${restaurantId}-sumTotal`}>${sumTotal}</p>
                }

            </div>
            <button className={`s${restaurantId}-btnPagar`} onClick={payAction}>PAGAR</button>
        </div>
    )
}

export default withFirebase(ProductList);

export { OrderLines };
