import React, { useState } from 'react';
import { withFirebase } from './context';
import { Rating } from 'primereact/rating';
import { child, set } from 'firebase/database';

const Survey = (props) => {
    const { firebase, menuId, tableNumber, savedScore, savedVolverias } = props;
    const [score, setScore] = useState(savedScore);
    const [volverias, setVolverias] = useState(savedVolverias);

    const handleVolveriasClick = event => {
        const newVolverias = event.target.value === 'si';
        setVolverias(newVolverias);
        //set(child(firebase.table(menuId, tableNumber), "volverias"), newVolverias);
    };

    const handleScoreClick = event => {
        setScore(event.value);
        //set(child(firebase.table(menuId, tableNumber), "score"), event.value);
    }

    const volveriasStyle = score ? {} : { visibility: 'hidden' };
    const graciasStyle = score && typeof volverias === 'boolean' ? {} : { visibility: 'hidden' };
    return (
        <div>
            <h1>¿Nos calificarías?</h1>
            <div>
                <p>¿Como fue tu experiencia hoy?</p>
                <Rating id="rating" readOnly={score} style={{ color: 'white' }} value={score} onChange={handleScoreClick} cancel={false} />
            </div>
            <div style={volveriasStyle}>
                <p>¿Volverías?</p>
                <p>
                    <label>
                        <input disabled={volverias !== undefined} type="radio" name="volverias" value="si" checked={volverias === true} onChange={handleVolveriasClick} />
                        Sí
                    </label>
                    <label>
                        <input disabled={volverias !== undefined} type="radio" name="volverias" value="no" checked={volverias === false} onChange={handleVolveriasClick} />
                        No
                    </label>
                </p>
                <p></p>
            </div>
            <p style={graciasStyle}>¡Gracias por responder!</p>
        </div>
    );
};

export default withFirebase(Survey);
