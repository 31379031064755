import { initializeApp } from 'firebase/app';
import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
} from 'firebase/auth';

import { getDatabase, ref as dbRef } from 'firebase/database';
import { getStorage, ref as stRef } from 'firebase/storage'
import FirebaseContext from './context';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
    constructor() {
        let app = initializeApp(config);
        //auth
        this.auth = getAuth(app);
        this.auth.useDeviceLanguage();
        this.googleProvider = new GoogleAuthProvider();
        //db
        this.db = getDatabase(app);
        this.storage = getStorage(app);
    }

    //auth
    doCreateUserWithEmailAndPassword = (email, password) => createUserWithEmailAndPassword(this.auth, email, password);
    doSignInWithEmailAndPassword = (email, password) => signInWithEmailAndPassword(this.auth, email, password);
    doSignOut = () => this.auth.signOut();
    doPasswordReset = email => sendPasswordResetEmail(this.auth, email);
    doSignInWithPopup = () => signInWithPopup(this.auth, this.googleProvider);
    //doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

    //db
    user = uid => dbRef(this.db, `/users/${uid}`);
    restaurant = uid => dbRef(this.db, `/users/${uid}/restaurant`);
    menu = uid => dbRef(this.db, `/users/${uid}/menu`);
    orders = uid => dbRef(this.db, `/orders/${uid}`);
    tables = uid => this.orders(uid);
    order = (uid, tid) => dbRef(this.db, `/orders/${uid}/${tid}`);
    table = (uid, tid) => this.order(uid, tid);
    uploadRef = (uid, filename) => stRef(this.storage, `users/${uid}/${filename}`);
}

export default Firebase;

export { FirebaseContext };