import React, { useState } from "react";
import { set } from "firebase/database";

function RestaurantForm(props) {
    const [authUser, setAuthUser] = useState(props.firebase.auth.currentUser);
    const [name, setName] = useState("");
    const [slogan, setSlogan] = useState("");
    const [tables, setTables] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSignOut = async () => {
        props.firebase.doSignOut().then(() => {
            setAuthUser(props.firebase.auth.currentUser);
        });
    }

    const handleSave = async (event) => {
        event.preventDefault();

        if (!name || !tables) {
            setErrorMessage('Nombre y cantidad de mesas son requeridos.');
            return;
        }

        //save restaurant in firebase.
        set(props.firebase.restaurant(authUser.uid), { name, slogan, tables })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div className="form-container">
            <h2>Datos del restaurante</h2>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <form>
                <div className="form-group">
                    <label htmlFor="name">Nombre</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        required
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="slogan">Slogan</label>
                    <input
                        type="text"
                        id="slogan"
                        name="slogan"
                        required
                        value={slogan}
                        onChange={(event) => setSlogan(event.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="tables">Cantidad de mesas</label>
                    <input
                        type="number"
                        id="tables"
                        name="tables"
                        required
                        value={tables}
                        onChange={(event) => setTables(event.target.value)}
                    />
                </div>
                <div className="form-group">
                    <button type="submit" onClick={handleSave}>Guardar</button>
                </div>
            </form>
            <hr />
            <div className="form-group">
                <button type="button" onClick={handleSignOut}>Cerrar sesión</button>
            </div>
        </div>
    )
}

export default RestaurantForm;